<template>
  <div>
    <v-card :loading='loading'>
      <v-card-title>
        <router-link
          class='font-weight-medium text-decoration-none me-2'
          :to="{ name: 'Checking-Set'}"
        >
          <v-tooltip
            color='#212121'
            top
          >
            <template v-slot:activator='{ on, attrs }'>
              <v-btn
                color='primary'
                v-bind='attrs'
                icon
                v-on='on'
              >
                <v-icon>{{ icons.mdiArrowLeftBold }}</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('back') }}</span>
          </v-tooltip>
        </router-link>
        {{ checkingSetResult.checkingset_name }}
        <StatusBlock class='ms-2' :status='String(checkingSetResult.checkingset_active_id)' />
        <v-spacer></v-spacer>
        <v-btn color='primary' @click='isAddNewDetailCheckingSet = true'>
          <v-icon>{{ icons.mdiPlus }}</v-icon>
          {{ $t('add_examination_lists') }}
        </v-btn>
      </v-card-title>
      <v-row class='px-5'>
        <v-col
          cols='12'
          md='6'
          lg='4'
        >
          <span class='font-weight-semibold'>{{ $t('code') }} :</span>
          {{ checkingSetResult.checkingset_id }}
        </v-col>
        <v-col
          cols='12'
          md='6'
          lg='4'
        >
          <span class='font-weight-semibold'> {{ $t('price') }} : </span>
          {{ checkingSetResult.checkingset_cost }}
        </v-col>
      </v-row>
      <v-data-table
        class='mt-3'
        :headers='columns'
        :items='dataTableList'
        dense
        :options.sync='options'
        :loading='loading'
        hide-default-footer
        disable-sort
        :loading-text="$t('data_loading')"
        :no-data-text="$t('no_information')"
      >
        <template v-slot:[`item.id`]='{ index }'>
          {{ index + 1 }}
        </template>
        <template v-slot:[`item.checkingset_map_active_id`]='{ item }'>
          <StatusBlock :status='String(item.checkingset_map_active_id)' />
        </template>
        <template v-slot:[`item.checkingset_map_cost`]='{ item }'>
          {{ item.checkingset_map_cost | formatPrice }}
        </template>
        <template v-slot:[`item.actions`]='{ item }'>
          <v-tooltip
            color='#212121'
            top
          >
            <template v-slot:activator='{ on, attrs }'>
              <v-btn
                icon
                color='primary'
                @click='dataEdit= item;isEditDetailCheckingSet = true'
                v-bind='attrs'
                v-on='on'
              >
                <v-icon>{{ icons.mdiPencilOutline }}</v-icon>
              </v-btn>
            </template>
            {{ $t('edit') }}
          </v-tooltip>
          <v-tooltip
            color='#212121'
            top
          >
            <template v-slot:activator='{ on, attrs }'>
              <v-btn
                icon
                color='primary'
                @click='dataEdit = item;isUpdateStatus = true'
                v-bind='attrs'
                v-on='on'
              >
                <v-icon>
                  {{ item.checkingset_map_active_id == 1 ? icons.mdiClose : icons.mdiCheck
                  }}
                </v-icon>
              </v-btn>
            </template>
            {{ item.checkingset_map_active_id == 1 ? $t('suspend') : $t('normal') }}
          </v-tooltip>
          <v-tooltip
            color='#212121'
            top
          >
            <template v-slot:activator='{ on, attrs }'>
              <v-btn
                icon
                color='primary'
                v-bind='attrs'
                v-on='on'
                @click='removeId = item.checkingset_map_id;isRemove = true'
              >
                <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
              </v-btn>
            </template>
            {{ $t('delete') }}
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
    <AddNewDetailCheckingSet v-model='isAddNewDetailCheckingSet'
                             :check-group-list='checkingGroupList'
                             :check-list='dataCheckingList'
                             @changeCourseGroup='onChangeCheckingGroup'
                             @onAdd='getCheckingSetById();getCheckingSetList();' />
    <EditCourseDetailSet v-model='isEditDetailCheckingSet'
                         :dataEdit='dataEdit'
                         :check-list='dataCheckingList'
                         @onUpdate='getCheckingSetById();getCheckingSetList();'
    />
    <RemoveDetailCheckingSet v-model='isRemove'
                             @onRemove='getCheckingSetById();getCheckingSetList();'
                             :remove-id='removeId' />
    <DetailCheckingSetUpdateStatus v-model='isUpdateStatus' :data-edit='dataEdit'
                                   @onUpdate='getCheckingSetById();getCheckingSetList();' />
  </div>
</template>

<script>
import { ref, watch, onMounted } from '@vue/composition-api'
import checkingSet from '@/api/checkingSet/checkingSet'
import AddNewDetailCheckingSet from './AddNewDetailCheckingSet'
import EditCourseDetailSet from './EditCheckingDetailSet'
import {
  mdiPlus,
  mdiPencilOutline,
  mdiCheck,
  mdiDeleteOutline,
  mdiClose,
  mdiArrowLeftBold,
} from '@mdi/js'
import { i18n } from '@/plugins/i18n'
import footerDataTables from '@/components/basicComponents/footerDataTables.json'
import footerDataTablesEN from '@/components/basicComponents/footerDataTablesEN.json'
import StatusBlock from '@/components/basicComponents/StatusBlock.vue'
import check from '@/api/examination/check'
import checkGroup from '@/api/examination/checkGroup'
import RemoveDetailCheckingSet from '@/views/checkSet/RemoveDetailCheckingSet'
import DetailCheckingSetUpdateStatus from '@/views/checkSet/DetailCheckingSetUpdateStatus'
import { formatPrice } from '@/plugins/filters'

export default {
  props: {},
  components: {
    EditCourseDetailSet,
    DetailCheckingSetUpdateStatus,
    RemoveDetailCheckingSet,
    StatusBlock,
    AddNewDetailCheckingSet,
  },
  filters: {
    formatPrice,
  },
  setup(_, { root, emit }) {
    const checkingSetResult = ref({})
    const isAddNewDetailCheckingSet = ref(false)
    const checkingGroupList = ref([])
    const checkingGroupId = ref('')
    const options = ref({
      itemsPerPage: -1,
    })
    const footer = ref(i18n.locale === 'en' ? footerDataTablesEN : footerDataTables)
    const search = ref('')
    const dataCheckingList = ref([])
    const dataTableList = ref([])
    const isEditDetailCheckingSet = ref(false)
    const dataEdit = ref({})
    const columns = ref([
      {
        text: '#',
        align: 'start',
        value: 'id',
        width: 70,
      },
      {
        text: i18n.t('code'),
        value: 'checking_code',
      },
      {
        text: i18n.t('name'),
        value: 'checking_name',
      },
      {
        text: i18n.t('amountUnit'),
        value: 'checking_amount_unit',
        align: 'end',
      },
      {
        text: i18n.t('price'),
        value: 'checkingset_map_cost',
        align: 'end',
      }, {
        text: i18n.t('status'),
        value: 'checkingset_map_active_id',
        align: 'center',
      },
      {
        text: i18n.t('option'),
        value: 'actions',
        sortable: false,
        align: 'center',
      },
    ])
    const loading = ref(false)
    const isRemove = ref(false)
    const isUpdateStatus = ref(false)
    const removeId = ref('')
    onMounted(() => {
      getCheckingGroup()
      getCheckingSetList()
      getCheckingSetById()
    })

    const getCheckingGroup = () => {
      checkGroup.checkGroupList({
        searchtext: '',
        checkgroup_status_id: 1,
      }).then(res => {
        checkingGroupList.value = res
        checkingGroupList.value.unshift({
          checkgroup_id: '',
          checkgroup_name: i18n.t('all'),
        })
        getCheckList()
      })
    }

    const getCheckList = () => {
      check.checkList({
        searchtext: '',
        checkgroup_id: checkingGroupId.value,
        checking_status_id: 1,
        checking_type_id: '',
        active_page: 1,
        per_page: '0',
      }).then(res => {
        dataCheckingList.value = res.data
      }).catch(error => {
        console.log('error : ', error)
      })
    }


    const getCheckingSetList = () => {
      loading.value = true
      checkingSet.getCheckingInSetList({
        checkingset_id: root.$route.params.id,
      }).then(res => {
        dataTableList.value = res.data
        loading.value = false
      }).catch(err => {
        loading.value = false
        console.log(err)
      })
    }

    const getCheckingSetById = () => {
      checkingSet.getCheckingSetById(root.$route.params.id).then(res => {
        checkingSetResult.value = res.data
      }).catch(err => {
        console.log(err)
      })
    }


    const onChangeCheckingGroup = (value) => {
      checkingGroupId.value = value
      getCheckList()
    }

    return {
      checkingSetResult,
      isAddNewDetailCheckingSet,
      checkingGroupList,
      getCheckingSetById,
      dataCheckingList,
      columns,
      onChangeCheckingGroup,
      dataTableList,
      options,
      footer,
      dataEdit,
      getCheckingSetList,
      loading,
      checkingGroupId,
      isEditDetailCheckingSet,
      isRemove,
      removeId,
      isUpdateStatus,
      icons: {
        mdiPlus,
        mdiPencilOutline, mdiCheck, mdiDeleteOutline,
        mdiArrowLeftBold,
        mdiClose,
      },
    }
  },

}
</script>

<style scoped>

</style>
