<template>
  <div>
    <v-dialog v-model='isAddNewDetailCheckingSet' persistent max-width='500'>
      <v-card>
        <v-card-title>
          {{ $t('add_examination_lists') }}
        </v-card-title>
        <v-form
          ref='formAddDetailCourseSet'
          @submit.prevent='createDetailCheckingSet'
        >
          <v-card-text>
            <!--            <v-autocomplete
                          v-model='checkinggroup_id'
                          outlined
                          dense
                          :label="$t('Examination category')"
                          :items='checkGroupList'
                          :no-data-text="$t('no_information')"
                          class='mt-5'
                          hide-details='auto'
                          item-text='checkgroup_name'
                          item-value='checkgroup_id'
                        ></v-autocomplete>-->
            <v-autocomplete
              v-model='checking_id'
              outlined
              dense
              :label="$t('Checklist')"
              :rules='[required]'
              :items='checkList'
              hide-details='auto'
              class='mt-5'
              :no-data-text="$t('no_information')"
              return-object
              item-text='checking_name'
              item-value='checking_id'
            >
              <template v-slot:item='{ item }'>
                <span v-if='item.checking_code' class='me-1'>
                  ({{ item.checking_code }})
                </span>
                <span>
                  {{ item.checking_name }}
                </span>
              </template>
              <template v-slot:selection='{ item }'>
                <span v-if='item.checking_code' class='me-1'>
                  ({{ item.checking_code }})
                </span>
                <span>
                  {{ item.checking_name }}
                </span>
              </template>
            </v-autocomplete>
            <v-text-field
              v-model.trim='checkingset_map_cost'
              hide-details='auto'
              class='mt-5'
              type='number'
              :label="$t('price')"
              outlined
              :rules='[required]'
              dense
            >
            </v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color='primary'
              type='submit'
              :loading='loading'
              :disabled='loading'
            >
              {{ $t('save') }}
            </v-btn>
            <v-btn
              color='secondary'
              @click="$emit('update:isAddNewDetailCheckingSet',false)"
              outlined
            >
              {{ $t('cancel') }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import { required, textValidation } from '@/@core/utils/validation'
import checkingSet from '@/api/checkingSet/checkingSet'
import store from '@/store'

export default {
  model: {
    prop: 'isAddNewDetailCheckingSet',
    event: 'update:isAddNewDetailCheckingSet',
  },
  props: {
    isAddNewDetailCheckingSet: {
      type: Boolean,
      default: false,
    },
    checkList: {
      type: Array,
      default: () => [],
    },
    checkGroupList: {
      type: Array,
      default: () => [],
    },

  },
  components: {},
  setup(props, { root, emit }) {
    const checkingset_map_cost = ref('')
    const checkinggroup_id = ref('')
    const checking_id = ref('')
    const formAddDetailCourseSet = ref(false)
    const loading = ref(false)

    const createDetailCheckingSet = () => {
      const isFormValid = formAddDetailCourseSet.value.validate()
      if (!isFormValid) return
      loading.value = true
      checkingSet.CheckingInSetAdd({
        checkingset_id: root.$route.params.id,
        checking_id: checking_id.value.checking_id,
        checkingset_map_cost: checkingset_map_cost.value,
      }).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        emit('update:isAddNewDetailCheckingSet', false)
        emit('onAdd')
        loading.value = false
        clearData()
      }).catch(err => {
        console.log('error : ', err)
        loading.value = false
      })
    }

    const clearData = () => {
      checkingset_map_cost.value = ''
      checking_id.value = ''
    }

    watch(checkinggroup_id, value => {
      checking_id.value = ''
      emit('changeCourseGroup', value)
    })

    watch(checking_id, value => {
      checkingset_map_cost.value = value.checking_cost
    })
    watch(() => props.isAddNewDetailCheckingSet, value => {
      clearData()
    })

    return {
      checking_id,
      checkinggroup_id,
      checkingset_map_cost,
      loading,
      createDetailCheckingSet,
      formAddDetailCourseSet,
      required,
      textValidation,
    }
  },

}
</script>
