<template>
  <div>
    <v-dialog v-model='isEditCheckingDetailSet' persistent max-width='500'>
      <v-card>
        <v-card-title>
          {{ $t('edit_list') }}
        </v-card-title>
        <v-form
          ref='formEditCheckingDetailSet'
          @submit.prevent='updateDetailCheckingSet'
        >
          <v-card-text>
            <v-autocomplete
              v-model='checking_id'
              outlined
              dense
              :label="$t('Checklist')"
              :rules='[required]'
              :items='checkList'
              hide-details='auto'
              disabled
              class='mt-5'
              :no-data-text="$t('no_information')"
              item-text='checking_name'
              item-value='checking_id'
            ></v-autocomplete>
            <v-text-field
              v-model.trim='dataEditLocal.checkingset_map_cost'
              hide-details='auto'
              class='mt-5'
              type='number'
              :label="$t('price')"
              outlined
              :rules='[required]'
              dense
            >
            </v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color='primary'
              type='submit'
              :loading='loading'
              :disabled='loading'
            >
              {{ $t('save') }}
            </v-btn>
            <v-btn
              color='secondary'
              @click="$emit('update:isEditCheckingDetailSet',false)"
              outlined
            >
              {{ $t('cancel') }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import { required, textValidation } from '@/@core/utils/validation'
import checkingSet from '@/api/checkingSet/checkingSet'
import store from '@/store'

export default {
  model: {
    prop: 'isEditCheckingDetailSet',
    event: 'update:isEditCheckingDetailSet',
  },
  props: {
    isEditCheckingDetailSet: {
      type: Boolean,
      default: false,
    },
    checkList: {
      type: Array,
      default: () => [],
    },
    dataEdit: {
      type: Object,
      default:
        () => ({}),
    }
    ,
  },
  components: {}
  ,
  setup(props, { root, emit }) {
    const checkingset_map_cost = ref('')
    const checking_id = ref('')
    const formEditCheckingDetailSet = ref(false)
    const loading = ref(false)
    const dataEditLocal = ref({})

    const updateDetailCheckingSet = () => {
      const isFormValid = formEditCheckingDetailSet.value.validate()
      if (!isFormValid) return
      loading.value = true
      checkingSet.CheckingInSetUpdate({
        checkingset_map_id: dataEditLocal.value.checkingset_map_id,
        checkingset_map_cost: dataEditLocal.value.checkingset_map_cost,
      }).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        emit('update:isEditCheckingDetailSet', false)
        emit('onUpdate')
        loading.value = false
        clearData()
      }).catch(err => {
        console.log('error : ', err)
        loading.value = false
      })
    }

    const clearData = () => {
      checkingset_map_cost.value = ''
      checking_id.value = ''
    }


    watch(() => props.isEditCheckingDetailSet, value => {
      if (value) {
        dataEditLocal.value = JSON.parse(JSON.stringify(props.dataEdit))
        checking_id.value = dataEditLocal.value.checking_id
      }
    })

    return {
      checkingset_map_cost,
      loading,
      updateDetailCheckingSet,
      dataEditLocal,
      formEditCheckingDetailSet,
      required,
      checking_id,
      textValidation,
    }
  }
  ,

}
</script>
